/**
 ** Created By liuqi on 2019-08-07
 */
import React from 'react'

import Layout from '../components/layout'
import Tips from "../components/tips/tips"
import ContactUs from "../components/contactUs/contactUs"

import './masterp.less'
import {Video} from '../utils/constant'

import iconGexinghua from '../images/icon_gexinghua.png'
import iconBianjie from '../images/icon_bianjie.png'
import iconFuwu from '../images/icon_fuwu.png'
import iconShishihudong from '../images/icon_shishihudong.png'
import picCertificate from '../images/pic-certificate.png'
import picSchool1 from '../images/picSchool1.jpg'
import picSchool2 from '../images/picSchool2.jpg'
import picSchool3 from '../images/picSchool3.jpg'
import picSchool4 from '../images/picSchool4.jpg'
import picSchool5 from '../images/picSchool5.jpg'
import picSchool6 from '../images/picSchool6.jpg'
import picSchool7 from '../images/picSchool7.jpg'
import picSchool8 from '../images/picSchool8.jpg'
import picSchool9 from '../images/picSchool9.jpg'
import picSchool10 from '../images/picSchool10.jpg'
import picVideoMaster from '../images/pic_videoMaster.jpg'
import picData1 from '../images/pic_data1.png'
import picData2 from '../images/pic_data2.png'
import picData3 from '../images/pic_data3.png'


class MasterP extends React.Component{

  moveEnter(e) {
    if (e.target.tagName.toLowerCase() !== 'li') {
      return
    }
    e.target.classList.remove('cardAnimationEnd');
    e.target.classList.add('cardAnimationStart');
  }

  moveOut(e) {
    if (e.target.tagName.toLowerCase() !== 'li') {
      return
    }
    e.target.classList.remove('cardAnimationStart');
    e.target.classList.add('cardAnimationEnd');
  }

  moveEnter2(e) {
    if (e.target.tagName.toLowerCase() !== 'li') {
      return
    }
    e.target.classList.remove('card2AnimationEnd');
    e.target.classList.add('card2AnimationStart');
  }

  moveOut2(e) {
    if (e.target.tagName.toLowerCase() !== 'li') {
      return
    }
    e.target.classList.remove('card2AnimationStart');
    e.target.classList.add('card2AnimationEnd');
  }

  imgScaleStart(e) {
    e.target.classList.remove('imgAnimationEnd');
    e.target.classList.add('imgAnimationStart');
  }

  imgScaleEnd(e) {
    e.target.classList.remove('imgAnimationStart');
    e.target.classList.add('imgAnimationEnd');
  }

  play(e) {
    if (!e.target) {
      return;
    }
    e.preventDefault();
    const video = e.target;
    if (video.nodeName.toLowerCase() === 'video') {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  }

  render() {
    return (
      <Layout>
        {/*  banner  */}
        <section className='masterTop'></section>
        {/*  教学特点  */}
        <section className='masterSectionGrey'>
          <div className='masterInnerBox'>
            <h3 className='commonTitleH3'>简单智课堂教学特点</h3>
            <p className='commonTitleP'>学习变简单，教育更公平</p>
            <ul className='masterTeachCharacter'>
              <li onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                <div>
                  <img className='masterTeachCharacterIcon' src={iconGexinghua} alt='' />
                  <h4 className='commonSubTitle'>个性化教学</h4>
                  <p className='commonSubTitleP'>班内分层 精准教学</p>
                </div>
              </li>
              <li onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                <div>
                  <img className='masterTeachCharacterIcon' src={iconBianjie} alt='' />
                  <h4 className='commonSubTitle'>操作便捷</h4>
                  <p className='commonSubTitleP'>完整软硬件系统</p>
                </div>
              </li>
              <li onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                <div>
                  <img className='masterTeachCharacterIcon' src={iconFuwu} alt='' />
                  <h4 className='commonSubTitle'>教研支持服务</h4>
                  <p className='commonSubTitleP'>可持续教学科研支持</p>
                </div>
              </li>
              <li onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                <div>
                  <img className='masterTeachCharacterIcon' src={iconShishihudong} alt='' />
                  <h4 className='commonSubTitle'>实时互动</h4>
                  <p className='commonSubTitleP'>学校常态使用数据输出</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        {/*  解决问题   */}
        <section className='masterSectionResolve'>
          <div className='masterInnerBox'>
            <h3 className='commonTitleH3'>能给学校解决什么问题</h3>
            <p className='commonTitleP'> 学习变简单，教育更公平</p>
            <ul className='masterResolveQues'>
              <li onMouseEnter={this.moveEnter2.bind(this)} onMouseLeave={this.moveOut2.bind(this)}>
                <div>
                  <h4 className='commonSubTitle'>提高学校竞争力</h4>
                  <p className='commonSubTitleP'>希望通过信息化手段，提升教学质量，提高学校竞争力的学校。</p>
                </div>
              </li>
              <li onMouseEnter={this.moveEnter2.bind(this)} onMouseLeave={this.moveOut2.bind(this)}>
                <div>
                  <h4 className='commonSubTitle'>实现个性化教学</h4>
                  <p className='commonSubTitleP'>希望实现个性化教学，提高学生自主学习力的学校。</p>
                </div>
              </li>
              <li onMouseEnter={this.moveEnter2.bind(this)} onMouseLeave={this.moveOut2.bind(this)}>
                <div>
                  <h4 className='commonSubTitle'>增强师资力量</h4>
                  <p className='commonSubTitleP'>精英教师当助教，增强师资力量，加速老师成长。</p>
                </div>
              </li>
              <li onMouseEnter={this.moveEnter2.bind(this)} onMouseLeave={this.moveOut2.bind(this)}>
                <div>
                  <h4 className='commonSubTitle'>助力信息化验收</h4>
                  <p className='commonSubTitleP'>助力完成教育部信息化验收任务。</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        {/*  达成的目标 */}
        <section className='masterSectionGoal'>
          <div className='masterInnerBox'>
            <h3 className='commonTitleH3'>致力于达成的目标</h3>
            <p className='commonTitleP'> 学习变简单，教育更公平</p>
            <ul className='masterGoal'>
              <li className='helpMaster' onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                <h4 className='commonSubTitle'>
                  <span className='helpText'>帮助</span>校长
                </h4>
                <p className='commonSubTitleP'>20万节精品微课“入校”</p>
                <p className='commonSubTitleP'>提升师资水平</p>
                <p className='commonSubTitleP'>提升学校影响力</p>
              </li>
              <li className='helpTeacher' onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                <h4 className='commonSubTitle'>
                  <span className='helpText'>帮助</span>老师
                </h4>
                <p className='commonSubTitleP'>老师讲得少了</p>
                <p className='commonSubTitleP'>精准辅导时间多了</p>
              </li>
              <li className='helpStudent' onMouseEnter={this.moveEnter.bind(this)} onMouseLeave={this.moveOut.bind(this)}>
                <h4 className='commonSubTitle'>
                  <span className='helpText'>帮助</span>学生
                </h4>
                <p className='commonSubTitleP'>尖子生吃的饱</p>
                <p className='commonSubTitleP'>后进生跟的上</p>
              </li>
            </ul>
          </div>
        </section>
        {/* 效果如何 */}
        <section className='masterSectionEffect'>
          <div className='masterInnerBox'>
            <h3 className='commonTitleH3'>效果如何</h3>
            <p className='commonTitleP'> 学习变简单，教育更公平</p>
            <ul className='masterEffect'>
              <li>
                <p className='masterData'>
                  <img alt='' src={picData1} />
                </p>
                <p className='commonSubTitleP'>
                  13个省、<br/>
                  数十家基地示范校。
                </p>
              </li>
              <li>
                <p className='masterData'>
                  <img alt='' src={picData2} />
                </p>
                <p className='commonSubTitleP'>
                  老师单校单周<br/>
                  使用频次达7.58次。
                </p>
              </li>
              <li>
                <p className='masterData'>
                  <img alt='' src={picData3} />
                </p>
                <p className='commonSubTitleP'>
                  学生单科单周<br/>
                  课上完成题目数达48道。
                </p>
              </li>
            </ul>
            <div className='videoWrap'>
              <video className='video'
                     controls
                     controlsList='nodownload'
                     poster={picVideoMaster} onClick={this.play.bind(this)} loop>
                <source
                  src={Video.ELunChun}
                  type="video/mp4"/>
                你的浏览器不支持 html5 video播放
              </video>
            </div>
          </div>
        </section>
        {/* 国家专利技术支撑 */}
        <section className='masterSectionCertificate'>
          <div className='masterInnerBox'>
            <h3 className='commonTitleH3'>国家专利技术支撑</h3>
            <p className='commonTitleP'>学习变简单，教育更公平</p>
            <p className='commonSubTitleP'>
              创始团队来源于北京大学，与北京大学共有国家专利 <br/>（专利号：ZL200710099746.2） （专利种类：发明专利）
            </p>
            <img src={picCertificate} alt='' />
          </div>
        </section>
        {/* 我们的品牌主张 */}
        <section className='masterSectionBrand'>
          <div className='masterInnerBox'>
            <h3 className='commonTitleH3'>我们的品牌主张</h3>
            <p className='commonTitleP'>学习变简单，教育更公平</p>
            <ul className='masterBrand'>
              <li onMouseEnter={this.moveEnter.bind(this)}
                  onMouseLeave={this.moveOut.bind(this)}>
                <div>
                  <h3 className='title'>简单校训</h3>
                  <p className='content'>千难万阻简单应对,人生注定不简单</p>
                </div>
              </li>
              <li onMouseEnter={this.moveEnter.bind(this)}
                  onMouseLeave={this.moveOut.bind(this)}>
                <div>
                  <h3 className='title'>教学理念</h3>
                  <p className='content'>自主学习力，终身竞争力</p>
                </div>
              </li>
              <li onMouseEnter={this.moveEnter.bind(this)}
                  onMouseLeave={this.moveOut.bind(this)}>
                <div>
                  <h3 className='title'>简单使命</h3>
                  <p className='content'>科技让学习变简单,教育更公平</p>
                </div>
              </li>
            </ul>
          </div>
        </section>
        {/* 合作示范校学校 */}
        <section className='masterSectionSchool'>
          <div className='masterInnerBox'>
            <h3 className='commonTitleH3'>合作示范校</h3>
            <p className='commonTitleP'>学习变简单，教育更公平</p>
            <ul className='masterSchool'>
              <li>
                <img src={picSchool1} alt='' />
                <div className='mask'>内蒙古鄂伦春中学</div>
              </li>
              <li>
                <img src={picSchool2} alt='' />
                <div className='mask'>临汾行知学校</div>
              </li>
              <li>
                <img src={picSchool3} alt='' />
                <div className='mask'>河北邢台市清河中学</div>
              </li>
              <li>
                <img src={picSchool4} alt='' />
                <div className='mask'>景德镇昌河中学</div>
              </li>
              <li>
                <img src={picSchool5} alt='' />
                <div className='mask'>重庆中山外国语学校</div>
              </li>
              <li>
                <img src={picSchool6} alt='' />
                <div className='mask'>瑞丽民族中学</div>
              </li>
              <li>
                <img src={picSchool7} alt='' />
                <div className='mask'>安阳市第三十二中学</div>
              </li>
              <li>
                <img src={picSchool8} alt='' />
                <div className='mask'>海南罗驿实验学校</div>
              </li>
              <li>
                <img src={picSchool9} alt='' />
                <div className='mask'>四川自贡市·旭川中学</div>
              </li>
              <li>
                <img src={picSchool10} alt='' />
                <div className='mask'>无为县状元学校</div>
              </li>
            </ul>
          </div>
        </section>
        <Tips/>
        <ContactUs/>
      </Layout>
    )
  }
}

export default MasterP
